export const propCreationDate = 'data_creazione';
export const propName = 'nome';

export const orderAsc = 'asc';
export const orderDesc = 'desc';

export const loginPagePath = '/login';
export const loginTitle = 'Login';

export const notFoundPath = '/notfound';
export const forbiddenPath = '/forbidden';

export const nomiHostPath = '/nomihost';
export const organizzazioniPath = '/organizzazioni';
export const contrattiPath = '/contratti';
export const utentiPath = '/utenti';
export const utentiAbilitazioniPath = '/utentiAbilitazioni';
export const appSoftwarePath = '/applicazionisoftware';
export const applicazionisoftwareProfiloPath = '/applicazionisoftwareprofili';
export const dizionaridatiPath = '/dizionariodati';
export const applicazioniSoftwareFunzioniPath = '/funzioni';
export const applicazioniSoftwareProfiliFunzioniPath = '/applicazioniSoftwareProfiliFunzioni';
export const applicazioniSoftwareFunzioniOperazioniPath = '/applicazioniSoftwareFunzioniOperazioni';
export const applicazioniSoftwareFunzioniProfiliPath = '/profilo';
export const applicazioniSoftwareFunzioniGuidaPath = '/applicazioniSoftwareFunzioniGuida';
export const nomiHostOrganizzazioniPath = '/nomiHostOrganizzazioni';
export const funzioniProfiliPath = '/funzioniProfili';
export const funzioniComponentiPath = '/funzionicomponenti';
export const campiComponentiPath = '/campicomponenti';
export const componentiFunzioniPath = '/componentiFunzioni';

export const nameMinLength = 3;

export const cookieHome = 'home';
export const cookiePortal = 'portalesaas';
export const cookieExpires = 60 * 60 * 1000;
export const cookieCheckTime = 3000; //in millisec

export const sessionLogo = 'appLogo';
export const sessionOrg = 'portaleorg';
export const sessionProfil = 'portaleprofil';
export const sessionUser = 'utente'
export const sessionSkinTheme = 'skinTheme';
export const sessionAppPathName = 'appPathName';

export const cookieOrg = 'portaleorg';
export const cookieProfil = 'portaleprofil'
export const cookieUser = 'utente'
export const cookieSkinTheme = 'skinTheme';
export const cookieAppPathName = 'appPathName';
 
export const refreshTokenTime = 900000;

export const localStorageKeyLoggedApp = 'kgh1a';
export const localStorageValueLoggedApp = '(hfbb14%%£&';

export const gestioneTurniOrg = 'gestioneturniorg';

const domains = window.location.hostname.split(".");

export const secondLevelDomain = (domains[domains.length - 1] !== "localhost") ? domains[domains.length - 2] + "." + domains[domains.length - 1] : domains[domains.length - 1];

export const protocol = window.location.protocol + "//";

export const zuulUrl = (protocol + process.env.REACT_APP_API_THIRDLEVELDOMAIN + '.') + ((secondLevelDomain !== "localhost") ? (secondLevelDomain) : (process.env.REACT_APP_ZUUL_TEST_BE_API_URL));

export const authURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_AUTH_PATH) ? zuulUrl + process.env.REACT_APP_AUTH_PATH : "";

export const applicazioneURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_APPLICAZIONE_PATH) ? zuulUrl + process.env.REACT_APP_APPLICAZIONE_PATH : "";

export const portaleBEUrl = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_PORTALE_PATH) ? zuulUrl + process.env.REACT_APP_PORTALE_PATH : "";
