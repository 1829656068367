import CodiceFiscale from 'codice-fiscale-js';
import * as EmailValidator from 'email-validator';
import { Lookup, LookupObject } from '../models/Utils';
import { format } from 'date-fns';
import _ from 'lodash';
import { z } from 'zod';
import { cookiePortal } from './utilconst';

export function getTokenFromCookie() {
  return getCookie(cookiePortal);
}

/**
 * Ordina un array di oggetti in modo decrescente a seconda del valore di reference
 * @param {T[]} objects - una lista da ordinare
 * @param {string} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortDescObjectsBy<T>(objects: T[], reference: string | string[]): T[] {
  return sortAscObjectsBy(objects, reference).reverse();
}

/**
 * Ordina un array di oggetti in modo crescente a seconda del valore di reference
 * @param {T[]} objects - una lista da ordinare
 * @param {string} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortAscObjectsBy<T>(objects: T[], reference: string | string[]): T[] {
  return _.sortBy(
    objects,
    typeof reference === 'string' ? [reference] : reference
  );
}

/**
 *
 * @param {string} url - l'url corrente
 * @param {string} basePath - percorso base della funzione
 * @returns {boolean} Ritorna se l'url è un percorso base della funzione
 */
export function isUrlRoot(url: string, basePath: string): boolean {
  return url === basePath;
}

/**
 * Controlla l'ordinamento (case insensitive) di 2 stringhe ritornando un intero.
 * @param {string} a - Prima stringa
 * @param {string} b - Seconda stringa
 * @returns {int} -1: a viene prima di b\
 *           0: a e b sono simili/uguali\
 *           1: a viene dopo di b
 */
export function sortingStringCI(a: string, b: string): number {
  return a.toLowerCase() < b.toLowerCase()
    ? -1
    : a.toLowerCase() < b.toLowerCase()
      ? 0
      : 1;
}

/**
 * Controlla l'ordinamento (case sensitive) di 2 stringhe ritornando un intero.
 * @param {string} a - Prima stringa
 * @param {string} b - Seconda stringa
 * @returns {int} -1: a viene prima di b\
 *           0: a e b sono simili/uguali\
 *           1: a viene dopo di b
 */
export function sortingStringCS(a: string, b: string): number {
  return a < b
    ? -1
    : a < b
      ? 0
      : 1;

}

export function capitalize(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function isEquals(a: string, b: string, caseSensitive: boolean = false) {
  if (caseSensitive)
    return a === b;
  else
    return a.toLowerCase() === b.toLowerCase();
}

/**
 * Calcola il numero totale dei giorni in un mese
 * @param {number} month - mese da 1 a 12
 * @param {number} year - anno
 * @returns {number} - numero di giorni nel mese
 */
export function daysInMonth(month: number, year: number): number {
  return (month >= 1 && month <= 12) && year > 0
    ? new Date(year, month, 0).getDate()
    : 0
}

export function createLookup<T extends Record<string, any>>(object: T[], idAttribute: string, valueAttribute: string[], flag: string | null = null, separator: string = ' '): Lookup {
  let retval: Lookup = {};

  const isDate = (val: string) => {
    return /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/.test(val);
  }

  if (object) {
    if (flag) {
      object.forEach(elem => {
        if (elem[flag]) {
          let temp: string = "";
          valueAttribute.forEach((e, i, arr) => {
            let val = elem[e];

            if (isDate(val)) {
              val = format(new Date(val), 'dd/MM/yyyy HH:mm:ss')
            }

            if (val)
              temp += val + (i === (arr.length - 1) ? '' : ' ' + separator + ' ');
          });
          retval[elem[idAttribute]] = temp;
        }
      });
    } else {
      object.forEach(elem => {
        let temp = "";
        valueAttribute.forEach((e, i, arr) => {
          let val = elem[e];

          if (isDate(val)) {
            val = format(new Date(val), 'dd/MM/yyyy HH:mm:ss')
          }

          if (val)
            temp += val + (i === (arr.length - 1) ? '' : ' ' + separator + ' ');
        });
        retval[elem[idAttribute]] = temp;
      });
    }
    const temp = Object.entries(retval).sort((a, b) => a[1] < b[1] ? -1 : 1);
    retval = {};
    temp.forEach((elem) => {
      retval[elem[0]] = elem[1];
    });
  }

  return retval;
}

export function createLookupObject<T extends Record<string, any>>(object: T[], idAttribute: string, valueAttribute: string[], flag: string | null = null): LookupObject {
  let retval: LookupObject = {};

  if (object) {
    if (flag) {
      object.forEach(elem => {
        if (elem[flag]) {
          let temp: Record<string, any> = {};
          valueAttribute.forEach(e => {
            temp[e] = elem[e];
          });
          retval[elem[idAttribute]] = temp;
        }
      });
    }
    else {
      object.forEach(elem => {
        let temp: Record<string, any> = {};
        valueAttribute.forEach(e => {
          temp[e] = elem[e];
        });
        retval[elem[idAttribute]] = temp;
      });
    }
  }

  return retval;
}

export function getToday(): string {    // output: yyyy-mm-ddT00:00:00
  const d = new Date();
  const year = d.getFullYear()
  const month = d.getMonth() + 1;
  const date = d.getDate();

  let today = ""
  today += year;
  today += "-" + (month > 9 ? month : "0" + month);
  today += "-" + (date > 9 ? date : "0" + date);
  today += "T00:00:00";

  return today;
}

export const getDateDDMMYYYY = (d: Date): string => {
  if (!d || d.toString() === 'Invalid Date') return '';
  const date = getDateYYYYMMDD(d).split('-')
  return date.reverse().join('/');
}

export function getDateYYYYMMDD(date: Date): string {
  if (!date || date.toString() === 'Invalid Date') return '';
  return date.getFullYear() + '-' +
    ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '-' +
    (date.getDate() < 10 ? '0' : '') + date.getDate();
}

export function setCookie(name: string, val: string, domain: string | undefined, expire: number) {
  const date = new Date();
  const value = val;

  // Expire
  date.setTime(date.getTime() + expire);

  let newCookie = name + "=" + value + "; expires=" + date.toUTCString() + ";";
  if (window.location.protocol === "https:") newCookie = newCookie + " SameSite=Lax; Secure;";
  if (domain) newCookie = newCookie + " domain=" + domain + ";";
  document.cookie = newCookie;
}

export function getCookie(key: string) {
  const found = document.cookie.split(';').find(row => row.trim().startsWith(key + '='));
  return found ? found.split('=')[1] : null;
}

export function deleteCookie(key: string, domain: string | undefined) {
  let str = key + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
  if (window.location.protocol === "https:") str = str + " SameSite=Lax; Secure;";
  if (domain) str = str + " domain=" + domain + ";";
  document.cookie = str;
}

export const toBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let str = reader.result as string;
    resolve(str.split(",")[1]);
  }
  reader.onerror = error => reject(error);
});

export const toBase64PDF = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result as string);
  }
  reader.onerror = error => reject(error);
});

export const toBase64IMG = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  const dataDefineJpg = "data:image/jpg;base64,";
  const dataDefinePng = "data:image/png;base64,";
  reader.readAsDataURL(file);
  reader.onload = () => {
    let str = reader.result as string;
    if (file.type === "image/jpeg") {
      resolve(dataDefineJpg + str.split(",")[1]);
    } else if (file.type === "image/png") {
      resolve(dataDefinePng + str.split(",")[1]);
    }
  }
  reader.onerror = error => reject(error);
});

/*********VALIDATIONS***********/

export const validateEmail = (rowDataField: string | undefined | null, helperText: string | null, required: boolean) => {
  if (required && (rowDataField === undefined || rowDataField === null || !EmailValidator.validate(rowDataField))) {
    return helperText ? { isValid: false, helperText: helperText } : false;
  }
  else if (!required && (rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && !EmailValidator.validate(rowDataField))) {
    return helperText ? { isValid: false, helperText: helperText } : false;
  }
  else {
    return true;
  }
}

export const validateInputTextMinMaxLen = (rowDataField: string | undefined | null, minLen: number, maxLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length <= minLen : rowDataField.length < minLen) || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField.length !== 0 &&
    (
      (equal ? rowDataField.length <= minLen : rowDataField.length < minLen) || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
    )
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextMaxLen = (rowDataField: string | undefined | null, maxLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && (equal ? rowDataField.length >= maxLen : rowDataField.length < maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextMinLen = (rowDataField: string | undefined | null, minLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length <= minLen : rowDataField.length < minLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && (equal ? rowDataField.length <= minLen : rowDataField.length < minLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextFixedLen = (rowDataField: string | undefined | null, lenVal: number, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || rowDataField.length !== lenVal
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && rowDataField.length !== lenVal
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMinMax = (rowDataField: number | undefined | "" | null, minVal: number, maxVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || rowDataField === "" || (equal ? rowDataField <= minVal : rowDataField < minVal) || (equal ? rowDataField >= maxVal : rowDataField > maxVal)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal) || (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMax = (rowDataField: number | undefined | "" | null, minVal: number, maxVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null ||
    rowDataField === "" ||
    (
      (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMin = (rowDataField: number | undefined | "" | null, minVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null ||
    rowDataField === "" ||
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputDate = (start: Date | string | undefined | null, end: Date | string | undefined | null,
  equal: boolean, helperText: string | null, requiredStart: boolean, requiredEnd: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  if (requiredStart) {
    if (start === undefined || start === null || start === "") return notValidRet;
  }
  if (requiredEnd) {
    if (end === undefined || end === null || end === "") return notValidRet;
  }

  if (start === undefined || start === null || start === "") return true;
  if (end === undefined || end === null || end === "") return true;

  let stDate = start as Date;
  let stEnd = end as Date;
  if (equal && (stEnd.getTime() <= stDate.getTime())) return notValidRet;
  else if (!equal && (stEnd.getTime() < stDate.getTime())) return notValidRet;
  else return true;
}

export const validateRequired = (rowDataField: string | number | undefined | null, helperText: string | null) => {
  if (rowDataField === undefined || rowDataField === null || rowDataField === "") {
    return helperText ? { isValid: false, helperText: helperText } : false;
  } else return true;
}

export const validateCodiceFiscale = (codice: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (codice === undefined || codice === null || codice === "") return notValidRet;
  }
  if ((!required && codice && codice.length > 0) || required) {
    if (codice && CodiceFiscale.check(codice)) return true;
    else return notValidRet;
  } else return true;
}

export const validateDate = (date: string | undefined | null, minYear: number | null, maxYear: number | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (date === undefined || date === null || date === "") return notValidRet;
  }
  if (date && ((!required && date) || required)) {
    let checkDate = new Date(Date.parse(date));
    let year = checkDate.getFullYear();
    if (
      isNaN(checkDate.valueOf()) ||
      (minYear && year < minYear) ||
      (maxYear && year > maxYear) ||
      checkDate.getMonth() < 0 ||
      checkDate.getMonth() > 11 ||
      checkDate.getDate() < 1 ||
      checkDate.getDate() > 31
    ) return notValidRet;
    else return true;
  } else return true;
}

export const validateDateXGreaterThanY = (dateX: string | undefined | null, dateY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (dateX === undefined || dateX === null || dateX === "") return notValidRet;
  }
  if ((!required && dateX) || required) {
    if (dateY === undefined || dateY === null || dateY === "") return true;
    else {
      if (dateX && dateY && (isDateValid(dateX) || isDateTimeValid(dateX)) && (isDateValid(dateY) || isDateTimeValid(dateY))) {
        let checkDateX = new Date(Date.parse(dateX));
        let checkDateY = new Date(Date.parse(dateY));
        if ((equal && checkDateX.valueOf() === checkDateY.valueOf()) || checkDateX.valueOf() > checkDateY.valueOf()) return true;
        else return notValidRet;
      }
      return notValidRet
    }
  } else return true;
}

export const validateDateXSmallerThanY = (dateX: string | undefined | null, dateY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (dateX === undefined || dateX === null || dateX === "") return notValidRet;
  }
  if ((!required && dateX) || required) {
    if (dateY === undefined || dateY === null || dateY === "") return true;
    else {
      if (dateX && dateY && (isDateValid(dateX) || isDateTimeValid(dateX)) && (isDateValid(dateY) || isDateTimeValid(dateY))) {
        let checkDateX = new Date(Date.parse(dateX));
        let checkDateY = new Date(Date.parse(dateY));
        if ((equal && checkDateX.valueOf() === checkDateY.valueOf()) || checkDateX.valueOf() < checkDateY.valueOf()) return true;
        else return notValidRet;
      }
      return notValidRet;
    }
  } else return true;
}

export const validateTimeXGreaterThanY = (timeX: string | undefined | null, timeY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  const TimeSchema = z.string().refine((val) => timeRegex.test(val), {
    message: "Invalid time format, expected HH:MM",
  });

  if (!(timeX && timeY && TimeSchema.parse(timeX) && TimeSchema.parse(timeY))) {
    return notValidRet;
  }

  const timeX_hour = Number(timeX.split(':')[0]);
  const timeX_minute = Number(timeX.split(':')[1]);

  const timeY_hour = Number(timeY.split(':')[0]);
  const timeY_minute = Number(timeY.split(':')[1]);

  if (Number.isNaN(timeX_hour) || Number.isNaN(timeX_minute) || Number.isNaN(timeY_hour) || Number.isNaN(timeY_minute))
    return true;

  const dateX = new Date(new Date().setUTCHours(timeX_hour, timeX_minute)).toISOString();
  const dateY = new Date(new Date().setUTCHours(timeY_hour, timeY_minute)).toISOString();

  return validateDateXGreaterThanY(dateX, dateY, equal, helperText, required);
}

export const validateTimeXSmallerThanY = (timeX: string | undefined | null, timeY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  const timeX_hour = Number(timeX?.split(':')[0]);
  const timeX_minute = Number(timeX?.split(':')[1]);

  const timeY_hour = Number(timeY?.split(':')[0]);
  const timeY_minute = Number(timeY?.split(':')[1]);

  if (Number.isNaN(timeX_hour) || Number.isNaN(timeX_minute) || Number.isNaN(timeY_hour) || Number.isNaN(timeY_minute))
    return true;

  const dateX = new Date(new Date().setUTCHours(timeX_hour, timeX_minute)).toISOString();
  const dateY = new Date(new Date().setUTCHours(timeY_hour, timeY_minute)).toISOString();

  return validateDateXSmallerThanY(dateX, dateY, equal, helperText, required);
}

export const validateCellularNumber = (cellular: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cellular === undefined || cellular === null || cellular === "") return notValidRet;
  }
  if ((!required && cellular) || required) {
    const MNO = /^(\((00|\+)39\)|(00|\+)39)?\s?(313\s?[0-9]|351\s?[3-9]|352\s?[0]|33[013-9]\s?[0-9]|36[0-368]\s?[0-9]|381\s?[0-9]|34[0-9]\s?[0-9]|383\s?[0-9]|32[0234789]\s?[0-9]|355\s?[0-9]|38[089]\s?[0-9]|39[0-37]\s?[0-9])\d{2}\s?\d{4}$/;
    const MVNO = /^(\((00|\+)39\)|(00|\+)39)?(331\s?1|350\s?[0-9]|351\s?0|370\s?[137]|371\s?[01]|373\s?[0-9]|375\s?5|389\s?[0-9]|384\s?[0-9]|377\s?[1-5789])\d{2}\s?\d{4}$/;
    if (cellular && !MNO.test(cellular) && !MVNO.test(cellular)) return notValidRet;
    else return true;
  } else return true;
}

export const validateTelephoneNumber = (cellular: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cellular === undefined || cellular === null || cellular === "") return notValidRet;
  }
  if ((!required && cellular) || required) {
    const pattern = /^(0039|\+39)?\s?0[0-9]{7,10}$/;
    if (cellular && !pattern.test(cellular)) return notValidRet;
    else return true;
  } else return true;
}

export const validateTelephoneCellularNumber = (tcnumber: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (tcnumber === undefined || tcnumber === null || tcnumber === "") return notValidRet;
  }
  if ((!required && tcnumber) || required) {
    if (!validateTelephoneNumber(tcnumber, helperText, required) && !validateCellularNumber(tcnumber, helperText, required)) return notValidRet;
    else return true;
  } else return true;
}

export const validateCAP = (cap: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cap === undefined || cap === null || cap === "") return notValidRet;
  }
  if ((!required && cap) || required) {
    const pattern = /^[0-9]{5}$/;
    if (cap && !pattern.test(cap)) return notValidRet;
    else return true;
  } else return true;
}

export const validateAsDigitsByLength = (value: string | undefined | null, length: number, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (value === undefined || value === null || value === "") return notValidRet;
  }
  if ((!required && value) || required) {
    const pattern = new RegExp("^[0-9]{" + length + "}$");
    if (value && !pattern.test(value)) return notValidRet;
    else return true;
  } else return true;
}

export const validateAsDigitsByInterval = (value: string | undefined | null, minLength: number, maxLength: number, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (value === undefined || value === null || value === "") return notValidRet;
  }
  if ((!required && value) || required) {
    const pattern = new RegExp("^[0-9]{" + minLength + "," + maxLength + "}$");
    if (value && !pattern.test(value)) return notValidRet;
    else return true;
  } else return true;
}

function isDateValid(date: string | null | undefined): boolean {
  const dateValidator = z.string().date();

  const result = dateValidator.safeParse(date);

  return result.success;
}

function isDateTimeValid(date: string | null | undefined): boolean {
  const dateTimeValidator = z.string().datetime();

  const result = dateTimeValidator.safeParse(date);

  return result.success;
}