import { Box, useMediaQuery, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import HelpButton from '../buttons/HelpButton';
import useStyles from './style';
import useNavAppBar from './useNavAppBar';
import Funz from '../../../models/Funzione';
import { sideBarWidth } from '../../../utils/styleconst';
import RightActionButtonsDesktop from './RightActionButtons/RightActionButtonsDesktop';
import RightActionButtonsMobile from './RightActionButtons/RightActionButtonsMobile';

interface LeftSideNavbarWithAppBarProps {
  logoUri?: string | null,
  nomeOrganizzazione?: string | null,
}

const LeftSideNavbarWithAppBar: React.FC<LeftSideNavbarWithAppBarProps> = ({ children, logoUri, nomeOrganizzazione }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { open, mobileOpen, selectedRoute, titleID, titleBar, funzionalita, guidaHelp, variantDraw, handleDrawerOpen, handleDrawerHoverToggleOpen, handleMobileOpen,
    handleClickModulo, isModuleOpened,
    handleListItemClick, handleLogoClick
  } = useNavAppBar();

  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  const renderMacroFunz = (name: string, guidaHelp: boolean, funzionalita: Funz[], levelInd: number, ind: number) => {
    return <>
      <Box ml={levelInd * 2}>
        <ListItem style={{ padding: 0 }} button onClick={() => handleClickModulo(levelInd, ind)}>
          {levelInd === 1
            // macro
            ? <ListItemText primary={<Typography variant="h6" component="span">{name}</Typography>} />
            : <ListItemText primary={name} />
          }
          {isModuleOpened(levelInd, ind) ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isModuleOpened(levelInd, ind)} timeout="auto" unmountOnExit>
          <List disablePadding={levelInd !== 1} component="div" key={name} >
            {funzionalita.map((func, index) =>
              <div key={func.descrizione}>
                {!func.hidden && <>
                  <Divider variant="fullWidth" />
                  {(func.macro) ? (
                    <List key={func.descrizione}>
                      {func.funzioni && renderMacroFunz(func.descrizione, func.guida, func.funzioni, levelInd + 1, index)}
                    </List>
                  ) : (
                    <ListItem key={func.descrizione} button
                      className={classes.nested}
                      selected={selectedRoute === func.uri}
                      onClick={() => func.uri && handleListItemClick(func.uri, func.idApplicazioneSoftware, func.idApplicazioneSoftwareFunzione)}>
                      <ListItemText primary={func.descrizione} />
                      {func.guida && <HelpButton topics={{ [func.idApplicazioneSoftwareFunzione]: func.descrizione }} size='small' importance='secondary' />}

                    </ListItem>
                  )
                  }</>
                }
              </div>
            )}
            <br />
          </List>
        </Collapse>
      </Box>
    </>
  }

  const renderFunz = (functionalities: Funz[]) => {
    return (
      functionalities.map((func, index) => {
        return <div key={func.descrizione}>
          {!func.hidden && <>
            <Divider variant="fullWidth" />
            <List key={func.descrizione} className={classes.marginright}>
              {
                func.macro ? <>
                  {func.funzioni && renderMacroFunz(func.descrizione, func.guida, func.funzioni, 1, index)}
                </> :
                  <ListItem key={func.descrizione} button
                    className={classes.nested}
                    selected={selectedRoute === func.uri}
                    onClick={() => func.uri && handleListItemClick(func.uri, func.idApplicazioneSoftware, func.idApplicazioneSoftwareFunzione)}>
                    <ListItemText primary={func.descrizione} />
                  </ListItem>
              }
            </List>
          </>}
        </div>
      }
      )
    )
  }


  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,

        })}
      >
        <Toolbar className={classes.toolBar}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                onMouseOver={variantDraw === "temporary" && !open ? handleDrawerHoverToggleOpen : !mobileOpen ? handleMobileOpen : undefined}
                edge="start"
              //     className={clsx(classes.menuButton,  open && classes.hide  )}
              >
                <MenuIcon />
              </IconButton>

            </Grid>
            <Grid item xs={8}>
              <Grid container justifyContent="center" alignItems="center">
                <Typography align="center" className={classes.title} variant="h6" noWrap>
                  {titleBar}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {
                isMobile
                  ? <RightActionButtonsDesktop
                    guidaHelp={guidaHelp}
                    titleID={titleID}
                    titleBar={titleBar}
                  />
                  : <RightActionButtonsMobile
                    guidaHelp={guidaHelp}
                    titleID={titleID}
                    titleBar={titleBar}
                  />
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={clsx({ [classes.drawer]: open, }, classes.drawerDesktop,)}
        variant={variantDraw}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onMouseMove={variantDraw === "temporary" && open ? (e) => {
          if (e.clientX > sideBarWidth)
            handleDrawerHoverToggleOpen()
        } : undefined}
      >
        <div className={classes.drawerHeader}>
          <Grid container justifyContent="center">
            <img
              className={clsx(classes.logo, classes.pointer)}
              onClick={handleLogoClick}
              alt={"Logo " + nomeOrganizzazione}
              src={
                logoUri?.startsWith('data:')
                  ? logoUri
                  : 'data:image/png;base64,' + logoUri
              }
            />
          </Grid>
          <IconButton onClick={handleDrawerOpen}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        {
          renderFunz(funzionalita)
        }

      </Drawer>
      <Drawer
        className={clsx({ [classes.drawer]: mobileOpen, }, classes.drawerMobile,)}
        variant="temporary"
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onMouseMove={mobileOpen ? (e) => {
          if (e.clientX > sideBarWidth)
            handleDrawerHoverToggleOpen()
        } : undefined}
      >
        <div className={classes.drawerHeader}>
          <Grid container justifyContent="center">
            <img
              className={clsx(classes.logo, classes.pointer)}
              onClick={handleLogoClick}
              alt={"Logo " + nomeOrganizzazione}
              src={
                logoUri?.startsWith('data:')
                  ? logoUri
                  : 'data:image/png;base64,' + logoUri
              }
            />
          </Grid>
        </div>
        {
          renderFunz(funzionalita)
        }
      </Drawer>



      <main className={clsx(classes.content, classes.contentShift)}>
        <Box className={clsx(classes.contentHeader)} />
        <Box>{children}</Box>
      </main>
    </div >
  );
}
export default LeftSideNavbarWithAppBar;
