import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { getHomeUri } from "../../store/slices/funzionalitaSlice";
import { notFoundPath } from "../../utils/utilconst";

const PageLogin = () => {
  const history = useHistory();

  const token = useAppSelector(state => state.authInfo.token);
  const organization = useAppSelector(state => state.authInfo.idOrganization);
  const profil = useAppSelector(state => state.authInfo.idProfil);
  const homeApp = useAppSelector(getHomeUri);
  const homePortale = useAppSelector(state => state.authInfo.home);

  useEffect(() => {
    if ((!token || !organization || !profil) && homePortale) {
      window.location.href = homePortale;
    } else {
      history.push(homeApp ?? notFoundPath);
    }
  }, [organization, profil, token, homeApp, history, homePortale]);

  return <></>
}
export default PageLogin;